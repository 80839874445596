<template>
    <ToastNotification :message="message" :isVisible="isVisible" :bgColor="`red`" />
    <InviteTeammate :showEmail="showEmail" :toggleEmail="toggleEmail" />
    <!-- <div v-if="isLoading" class="loader w-full h-full"> -->
    <div class="loader" v-if="isLoading">
        <LoadingComponent />
    </div>
    <!-- </div> -->
    <div v-else>
        <div v-if="candidates?.length === 0" class="noCandidates">
            <div class="w-full flex justify-between mt-[2%] mb-[8%] after:absolute relative after:content-('') after:w-full after:h-0.5 after:bg-gray-200 after:bottom-0 after:left-0">
                <div class="flex flex-row items-center justify-start">
                    <h1 class="font-bold text-2xl w-full text-left title">{{ $t("Talents") }}</h1>
                    <Popper :content="$t('invite team members')" placement="bottom" :hover="true">
                        <button class="rounded-full text-lg text-[#7D8FB3]" @click="toggleEmail">
                            <font-awesome-icon :icon="['fas', 'user-plus']" />
                        </button>
                    </Popper>
                </div>
                <button class="button" @click="this.$router.push('/dashboard')">Dashboard</button>
            </div>
            <div class="w-full flex flex-col items-center">
                <div>
                    <img loading="lazy" decoding="async" src="../assets/Images/emptyTalents.svg" alt="" />
                </div>
                <div class="w-[60%] p-[2%] mt-[2%] secondText text-center">
                    <h3>{{ $t("No Candidates") }}</h3>
                </div>
            </div>
        </div>

        <div v-if="candidates?.length > 0" class="flex flex-col justify-items-start items-center gap-4">
            <div
                class="w-full flex flex-col lg:flex-row justify-between gap-2 mt-4 after:absolute relative after:content-('') after:w-full after:h-0.5 after:bg-gray-200 after:bottom-0 after:left-0 pb-4"
            >
                <div class="flex flex-row items-center justify-start">
                    <h1 class="font-bold text-2xl w-full text-left title">{{ $t("Talents") }}</h1>
                    <Popper :content="$t('invite team members')" placement="bottom" :hover="true">
                        <button class="rounded-full text-lg text-[#7D8FB3]" @click="toggleEmail">
                            <font-awesome-icon :icon="['fas', 'user-plus']" />
                        </button>
                    </Popper>
                </div>
                <div class="border-[3px] h-full w-full lg:w-1/3 mt-5 lg:mt-0 border-gray-200 rounded-md p-4 bg-white relative overflow-visible">
                    <input
                        type="text"
                        class="w-full h-full outline-none"
                        placeholder="Search by name..."
                        v-model="nameSearch"
                        @focus="showNames = true"
                        @blur="leaveSearch"
                        @input="typeName(nameSearch)"
                        @change="typeName(nameSearch)"
                    />
                    <ul
                        class="w-full h-fit hidden flex-col absolute left-0 -bottom-0 translate-y-full px-4 list-none bg-white border border-gray-200 max-h-56 z-50 overflow-y-scroll"
                        :class="showNames ? 'namesList pb-4 pt-2' : ''"
                    >
                        <li v-for="name in namesList" :key="name.email" class="text-slate-700 text-sm font-light" @click="selectName(name)">{{ name.name }}</li>
                    </ul>
                </div>
            </div>
            <div class="w-full flex flex-row justify-between items-center p-0 rounded h-fit overflow-hidden">
                <div class="filter flex flex-col lg:flex-row p-2 rounded">
                    <div class="input_group select border-[3px] w-full lg:w-1/3 border-gray-200">
                        <label class="required select_label" for="assessment">{{ $t("Project:") }}</label>

                        <select v-model="project" @change="filterCandidates(project)" style="width: max-content">
                            <option value="">All</option>
                            <option v-for="project in Store.getAllProjectList" :key="project.id" :value="project.id">
                                {{ project.name }}
                            </option>
                        </select>
                    </div>
                    <div class="input_group select border-[3px] w-full lg:w-1/3 border-gray-200">
                        <label class="required select_label" for="assessment">{{ $t("Status:") }}</label>

                        <select v-model="status" @change="candidatesStatus" style="width: 80%">
                            <option class="testt" value="passed">{{ $t("Attempted") }}</option>
                            <option value="invited">{{ $t("invited") }}</option>
                        </select>
                    </div>
                    <div class="input_group select border-[3px] w-full lg:w-1/6 border-gray-200">
                        <label class="required select_label" for="minScore">{{ $t("Min Score:") }}</label>
                        <input type="number" name="minScore" id="minScore" min="0" v-model="minScoreFilter" @change="filterMinScore" />
                    </div>
                    <div class="input_group select border-[3px] w-full lg:w-1/6 border-gray-200">
                        <label class="required select_label" for="maxScore">{{ $t("Max score:") }}</label>
                        <input type="number" name="maxScore" id="maxScore" max="100" v-model="maxScoreFilter" @change="filterMaxScore" />
                    </div>
                </div>
            </div>
            <div class="grid-row-widgets" v-if="projectId && filteredCandidates?.length > 0 && status === 'passed'">
                <div class="widget">
                    <div>
                        <span class="widget-title">{{ $t("Lowest Score") }}</span>
                        <span class="widget-info">{{ this.minScore }}%</span>
                    </div>
                    <img loading="lazy" decoding="async" src="../assets/Images/icons/bar-graph.svg" alt="bar-graph" />
                </div>
                <div class="widget-2">
                    <img loading="lazy" decoding="async" src="../assets/Images/icons/bar-chart-icon.svg" alt="bar-chart-icon" />
                    <div>
                        <span class="widget-title">{{ $t("Average Time") }}</span>
                        <span class="widget-info">{{ calculateAverageTime() }} </span>
                    </div>
                </div>
                <div class="widget background-1">
                    <div>
                        <span class="widget-title">{{ $t("Pass Rate") }}</span>
                        <span class="widget-info">{{ getSuccessRate() }} %</span>
                    </div>
                    <div>
                        <img loading="lazy" decoding="async" src="../assets/Images/icons/widget-chart.svg" alt="widget-chart" />
                        <span class="note good">{{ $t("Good") }}</span>
                    </div>
                </div>
                <div class="widget background-2">
                    <div>
                        <span class="widget-title">{{ $t("Best Score") }}</span>
                        <span class="widget-info">{{ this.maxScore }}%</span>
                    </div>
                    <div>
                        <img loading="lazy" decoding="async" src="../assets/Images/icons/widget-chart.svg" alt="widget-chart" />
                        <span class="note average">{{ $t("Might Be Too Low") }}</span>
                    </div>
                </div>
            </div>
            <div v-if="filteredCandidates?.length === 0" class="w-full flex flex-col items-center mt-[5%]">
                <div>
                    <img loading="lazy" decoding="async" src="../assets/Images/emptyTalents.svg" alt="" />
                </div>
                <div class="w-[60%] p-[2%] mt-[2%] secondText text-center">
                    <h3>{{ $t("No Candidates") }}</h3>
                </div>
            </div>
            <div v-else class="w-full">
                <div class="w-full">
                    <div class="grid-candidates w-full" v-if="status === 'passed'">
                        <CandidateCard
                            v-for="(candidateScore, index) in filteredCandidates"
                            :candidate="candidateScore"
                            :candidateInfo="getCandidate(candidateScore.candidate)"
                            :key="index"
                            :passed="true"
                            :backgroundHeader="[index % 3]"
                            :getCandidates="getCandidates"
                        />
                    </div>
                    <div class="w-full flex justify-center items-center">
                        <LoadingComponent v-if="bottomLoader" />
                    </div>

                    <div class="overflow-x-auto px-2 flex flex-col justify-start items-start w-full" v-if="status === 'invited'">
                        <!-- <InvitedCandidatesCard v-for="(candidate, index) in filteredCandidates" :candidate="candidate" :key="index" :passed="false" :backgroundHeader="[index % 3]" /> -->
                        <table class="w-full max-w-full text-left text-sm font-light bg-white border border-gray-200">
                            <thead class="border-b font-medium dark:border-neutral-200 dark:text-neutral-800" style="text-transform: uppercase">
                                <tr>
                                    <th scope="col" class="px-6 py-3 w-1/3">Email</th>
                                    <th scope="col" class="px-6 py-3">Invitation Date</th>
                                    <!-- <th scope="col" class="px-6 py-3">Job Title</th> -->
                                    <th scope="col" class="px-6 py-3">Project</th>
                                    <th scope="col" class="px-6 py-3"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="border-b dark:border-neutral-200 projectRow" v-for="(candidate, index) in filteredCandidates" :key="index">
                                    <td class="px-6 py-4 font-medium max-w-1/3">{{ candidate?.Email ? candidate?.Email : candidate?.candidate }}</td>
                                    <td class="px-6 py-4">{{ getDate(candidate.createdAt) }}</td>
                                    <!-- <td class="whitespace-nowrap px-6 py-4">{{ project.jobTitle }}</td> -->
                                    <td class="px-6 py-4">
                                        {{ getProjectName(candidate?.projectId) }}
                                    </td>
                                    <td class="px-6 py-4" @click="copyEmail(candidate?.Email)">
                                        <font-awesome-icon :icon="['far', 'copy']" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CandidateCard from "@/components/newCandidateCard.vue";
// import InvitedCandidatesCard from "@/components/InvitedCandidatesCard.vue";
import ToastNotification from "@/components/ToastNotification";
import InviteTeammate from "@/components/InviteTeammate.vue";
// import VueMultiselect from "vue-multiselect";
import LoadingComponent from "../components/LoadingComponent.vue";
import getDateAndTime from "@/helpers/getDateandTime";

import axios from "axios";
import { useStore } from "../store/index";
export default {
    name: "CandidatesView",
    components: { CandidateCard, ToastNotification, /* InvitedCandidatesCard, */ InviteTeammate, LoadingComponent },
    data() {
        return {
            minScoreFilter: 0,
            maxScoreFilter: 100,
            showNames: false,
            namesList: [],
            nameSearch: "",
            fullFilter: false,
            candidates: [],
            filteredCandidates: [],
            candidatesResults: [],
            invitations: [],
            assessment: "",
            project: "",
            companyAssessments: [],
            status: "passed",
            message: "",
            isVisible: false,
            showEmail: false,
            projectId: "",
            minScore: 0,
            maxScore: 0,
            isLoading: true,
            currentPage: 1,
            bottomLoader: false,
            bottomOfTheWindow: false,
        };
    },
    computed: {
        candidatesNames() {
            return this.candidates.map((candidate) => {
                return { name: candidate.First_name + " " + candidate.Last_name, email: candidate.Email };
            });
        },
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    methods: {
        filterMaxScore() {
            this.filteredCandidates = this.candidatesResults.filter((cand) => {
                return this.maxScoreFilter > this.calculateScore(cand).toFixed();
            });
        },
        getDate(date) {
            return getDateAndTime(date);
        },
        getProjectName(projectId) {
            const project = this.Store.projects.find((project) => project._id === projectId);
            return project?.name;
        },
        copyEmail(txt) {
            const emailText = txt;
            const el = document.createElement("textarea");
            el.value = emailText;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            alert("Email copied to clipboard!");
        },
        filterMinScore() {
            this.filteredCandidates = this.candidatesResults.filter((cand) => {
                return this.calculateScore(cand).toFixed() > this.minScoreFilter;
            });
        },
        leaveSearch() {
            setTimeout(() => {
                this.showNames = false;
            }, 1000);
        },
        selectName(str) {
            this.nameSearch = str.name;
            this.filteredCandidates = this.candidatesResults.filter((cand) => cand.candidate === str.email);
        },
        typeName(str) {
            const names = this.candidatesNames.filter((name) => {
                return name.name.toLocaleLowerCase().includes(str.toLowerCase());
            });
            this.namesList = names;
        },
        toggleEmail() {
            this.showEmail = !this.showEmail;
        },
        getCandidate(email) {
            return this.candidates.find((candidate) => candidate.Email === email);
        },

        filterCandidates(id) {
            this.projectId = id;
            if (id === "") {
                this.status === "passed" ? (this.filteredCandidates = this.candidatesResults) : (this.filteredCandidates = this.invitations);
                return;
            }

            if (this.status === "passed") {
                const newCandidates = this.candidatesResults.filter((result) => {
                    return result.projectId == id;
                });
                this.filteredCandidates = newCandidates;
                this.projectData();
                this.getSuccessRate();
                this.calculateAverageTime();
            } else {
                const newCandidates = this.invitations.filter((result) => {
                    return result.projectId == id;
                });
                this.filteredCandidates = newCandidates;
            }
        },
        projectData() {
            if (this.projectId) {
                // const project = this.Store.projects.find((project) => project._id === this.projectId);
                let projectResults = [];
                let maxScore = 0;
                this.filteredCandidates.map((candidate) => {
                    projectResults.push(this.calculateScore(candidate));
                });
                let minScore = projectResults[0];
                projectResults.map((result) => {
                    if (result > maxScore) {
                        maxScore = result;
                    }
                    if (result < minScore) {
                        minScore = result;
                    }
                });
                this.maxScore = maxScore;
                this.minScore = minScore;
            }
        },
        calculateAverageTime() {
            let averageTime = 0;
            this.filteredCandidates.map((candidate) => {
                averageTime += candidate.candidateTime;
            });
            averageTime = averageTime / this.filteredCandidates?.length;
            return this.calculateTime(averageTime);
        },
        calculateTime(time) {
            let minutes = Math.floor(time / (60 * 1000));
            if (minutes > 59) minutes = 59;

            let seconds = time % (60 * 1000);
            if (seconds > 59) seconds = 59;

            minutes = minutes.toString().padStart(2, "0");

            if (seconds < 10) {
                seconds = "0" + seconds.toString();
            } else {
                seconds = seconds.toString();
            }

            return `${minutes} min ${seconds} sec`;
        },
        getSuccessRate() {
            const project = this.Store.projects.find((project) => project._id === this.projectId);
            if (this.filteredCandidates?.length > 0) {
                const candidatesAbove80Percent = this.filteredCandidates.filter((candidate) => {
                    let score = 0;
                    candidate.results?.forEach((element) => {
                        score += (element.totalPoints * 100) / element.quesionsNbr;
                    });
                    const averageScore = score / candidate.results?.length;
                    return averageScore > project.min_score; // Filter candidates with scores over 80%
                });

                const numberOfCandidatesAbove80Percent = candidatesAbove80Percent?.length;
                const totalCandidates = this.filteredCandidates?.length;

                const percentageAbove80Percent = (numberOfCandidatesAbove80Percent / totalCandidates) * 100;
                return percentageAbove80Percent.toFixed(2);
            } else return 0;
        },
        calculateScore(candidate) {
            let score = 0;
            candidate.results?.forEach((element) => {
                score += (element.totalPoints * 100) / element.quesionsNbr;
            });
            const averageScore = score / candidate.results?.length;
            const roundedScore = averageScore.toFixed(2);
            return parseFloat(roundedScore);
        },
        candidatesStatus() {
            // this.status = str;
            switch (this.status) {
                case "passed":
                    this.filteredCandidates = this.candidatesResults;
                    this.project = "";
                    break;

                case "invited":
                    this.isLoading = true;
                    axios
                        .get("https://server.go-platform.com/company/invitations", {
                            withCredentials: true,
                        })
                        .then((response) => {
                            this.invitations = response.data.invitationsSent;
                            this.filteredCandidates = this.invitations;
                            this.project = "";
                            this.isLoading = false;
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            console.log({ error });
                        });
                    break;

                default:
                    break;
            }
        },
        getCandidates(page) {
            // this.isLoading = true;
            this.bottomLoader = true;
            let perPage = 6;
            axios
                .get(`https://server.go-platform.com/company/candidates?page=${page}&perPage=${perPage}`, {
                    withCredentials: true,
                })
                .then((response) => {
                    if (response.status == 400) {
                        this.message = "you need to send an assessment to candidates first to view this page";
                        this.isVisible = true;
                        setTimeout(() => {
                            this.isVisible = false;
                        }, 6000);
                        return;
                    }
                    console.log("response candidate", { response });
                    console.log({ candidates: this.candidates.length }, "---------------------------------------------=======================================-------------");
                    // Update candidates, candidatesResults, and invitations
                    this.candidates = [...this.candidates, ...response.data.candidatesData];
                    console.log({ candidates: this.candidates });
                    this.candidatesResults = [...this.candidatesResults, ...response.data.candidatesScores];
                    console.log({ candidates: this.candidatesResults });
                    // this.invitations = [...this.invitations, ...response.data.invitationsSent];
                    // console.log({ invitations: this.invitations });
                    this.filteredCandidates = [...this.candidatesResults];
                    this.isLoading = false;
                    this.bottomLoader = false;
                })
                .catch(() => {
                    // Handle error
                    this.isLoading = false;
                    this.bottomLoader = false;
                });
        },
        handleScroll() {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            let windowHeight = window.innerHeight;
            let documentHeight = document.documentElement.offsetHeight;

            let bottomOfWindow = Math.ceil(scrollTop + windowHeight) >= documentHeight;
            this.bottomOfTheWindow = bottomOfWindow;
        },
    },
    watch: {
        bottomOfTheWindow() {
            if (this.bottomOfTheWindow) {
                console.log(this.bottomOfTheWindow);
                this.currentPage++;
                this.getCandidates(this.currentPage);
            }
        },
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    async mounted() {
        this.Store.fetchProjects();
        this.isLoading = true;
        await this.Store.fetchCandidates();
        // this.candidates = this.Store.candidates;
        // this.candidatesResults = this.Store.candidatesScores;
        // // this.invitations = this.Store.invitationsSent;
        // this.filteredCandidates = this.candidatesResults;
        // console.log("------------------------------------------------", { filterCandidates: this.filterCandidates });
        this.getCandidates(1);
        window.addEventListener("scroll", this.handleScroll);

        // let config = {
        //     method: "get",
        //     maxBodyLength: Infinity,
        //     url: `https://server.go-platform.com/AssessmentTest/companyAssessments`,
        //     headers: {},
        //     withCredentials: true,
        // };

        // axios
        //     .request(config)
        //     .then((response) => {
        //         this.companyAssessments = response.data;
        //         console.log({ assessments: this.companyAssessments });
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
    },
};
</script>

<style scoped lang="scss">
.namesList {
    //height: fit-content;
    display: flex;
    transition: all 700ms ease;

    &::-webkit-scrollbar {
        display: none;
    }
}

.fullFilter {
    height: fit-content;
    transition: all 700ms ease;
}

.filterbtn {
    height: 0;
    transition: all 700ms ease;
}

.loader {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.noCandidates {
    h2 {
        color: #0f172a;
        font-family: Roboto;
        font-size: 36px;
        font-style: normal;
        font-weight: 300;
        line-height: 42px;
        /* 116.667% */
        letter-spacing: -0.72px;
    }

    .button {
        color: var(--secondary-primary-white, #fff);
        text-align: center;
        font-family: DM Sans;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 109.091% */
        letter-spacing: -0.44px;
        display: flex;
        width: 166px;
        height: 48px;
        padding: 10px 27px 14px 25px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 24px;
        background: #2196f3;
        cursor: pointer;
        transition: 0.2s ease-in;

        &:hover {
            &:hover {
                opacity: 0.85;
            }
        }
    }
}

.secondText {
    color: #0f172a;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 42px;
    /* 175% */
    letter-spacing: -0.48px;
}

.candidatesContainer {
    padding: 24px 8px;
    justify-content: start;
    padding-top: 1rem;

    @media screen and (min-width: 991.98px) {
        min-width: 100%;
        min-height: 75vh;
    }
}

.grid-row-widgets {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 16px;
    width: 100%;
    margin: 3% 0;
}

.widget,
.widget-2 {
    background: #fff;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
}

.background-1 {
    background: linear-gradient(135deg, #868cff 0%, #2196f3 100%);
}

.background-1 > div > .widget-info {
    color: #fff;
}

.background-1 > div > .widget-title {
    color: #e9edf7;
}

.background-2 {
    background: linear-gradient(135deg, #fab2ff 0%, #2196f3 100%);
}

.background-2 > div > .widget-info {
    color: #fff;
}

.background-2 > div > .widget-title {
    color: #e9edf7;
}

.widget-2 {
    justify-content: left;
}

.widget-2 > img {
    margin-right: 16px;
}

.widget-title {
    display: block;
    font-weight: 500;
    font-size: 14px;
    color: #a3aed0;
    margin-bottom: 8px;
}

.widget-info {
    display: block;
    font-weight: 700;
    font-size: 24px;
    color: #1b2559;
}

.note {
    display: block;
    width: fit-content;
    margin-left: auto;
    font-weight: 500;
    font-size: 10px;
    text-align: center;
    color: #ffffff;
    border-radius: 7px;
    padding: 4px;
}

.good {
    background: #05cd99;
}

.average {
    background: #ffd133;
}

.title {
    // margin: 3% 0;
    color: #0f172a;
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: 42px;
    /* 116.667% */
    letter-spacing: -0.72px;
}

.filter {
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    // margin: 3rem 0;
    width: 100%;
}

.grid-candidates {
    display: grid;
    // justify-content: flex-start;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
    //row-gap: 40px;
    margin-top: 24px;

    // flex-wrap: wrap;
    @media screen and (max-width: 991.98px) {
        grid-template-columns: 1fr;
    }
}

.select {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
    //border: 1px solid #ccd7ec;
    border-radius: 6px;
    //box-shadow: 0 0 5px 0px rgba(0, 0, 0, 15%);
    padding: 1rem;
    //width: 100%;
    background-color: white !important;

    > :nth-child(2) {
        width: 50%;
        // float: right;
        right: 0;
        border: none;
        background: transparent;

        :nth-child(2) {
            border: none;
            background: transparent;
        }

        &:focus {
            outline: none !important;
            // width: fit-content;
            border: none;
        }
    }

    .select_label {
        // position: absolute;
        // top: 50%;
        // left: 5%;
        // z-index: 5;
        padding: 0 1% 0 0;
        // transform: translateY(-50%);
        pointer-events: none;
        // background: #fff;
        transition: all 0.3s ease;
        text-wrap: nowrap;
    }
}

.select2 {
    width: 30%;
}

.title {
    // width: 96%;
    padding: 0 2rem;
    text-align: left;
    margin-right: 0;
    color: var(--secondary-grey-900, #0f172a);
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    /* 116.667% */
    letter-spacing: -0.72px;

    &::after {
        content: "|";
        height: 40px;
        width: 2px;
        color: #656669;
        margin-left: 30px;
        opacity: 0.2;
    }
}

.loader {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;

    .projectRow {
        cursor: pointer;

        &:hover {
            background-color: #2195f32c;
        }
    }

    @media (max-width: 768px) {
        table {
            display: block;
            //  overflow-x: scroll;
            white-space: nowrap;
        }
    }

    #table_body {
        width: 95%;
        max-height: calc(89% - 1.6rem);

        margin: 0.8rem auto;
        border-radius: 0.6rem;

        overflow-x: scroll;
        //        overflow: overlay;
    }

    #table_body::-webkit-scrollbar {
        width: 0.5rem;
        height: 0.5rem;
    }

    #table_body::-webkit-scrollbar-thumb {
        border-radius: 0.5rem;
        background-color: #0004;
        visibility: hidden;
    }

    #table_body:hover::-webkit-scrollbar-thumb {
        visibility: visible;
    }

    table {
        width: 100%;
    }

    thead th {
        top: 0;
        left: 0;
        cursor: pointer;
        text-transform: capitalize;
    }

    tbody tr td,
    tbody tr td p,
    tbody tr td img {
        transition: 0.2s ease-in-out;
    }
}
</style>
