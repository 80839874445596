<template>
    <div class="cheating-container">
        <div class="back-container">
            <div class="cursor-pointer" @click="goBackFunction()">
                <span> <font-awesome-icon class="mr-1" :icon="['fas', 'arrow-left']" />Go Back </span>
            </div>
        </div>
        <div v-if="isLoading" class="loading">
            <LoaderComponentBlue />
        </div>

        <div v-else class="cheating-wrapper">
            <div class="identity-card">
                <div class="identity-container">
                    <div class="left-side">
                        <img loading="lazy" decoding="async" :src="'data:image/png;base64,' + this.candidate.avatar" alt="" />
                    </div>
                    <div class="right-side">
                        <p><strong>First name : </strong> {{ this.candidate.first_name }}</p>
                        <p><strong>Last name : </strong>{{ this.candidate.second_name }}</p>
                        <p><strong>Email address : </strong>{{ this.candidate.email }}</p>
                        <p><strong>Phone number : </strong>{{ this.candidate.phone }}</p>
                    </div>
                </div>
            </div>

            <div class="separator">
                <div class="line"></div>
                <h3>Captured Photos</h3>
                <div class="line"></div>
            </div>
            <div class="captured-photos">
                <div v-for="photo in captured_photos" :key="photo._id">
                    <img loading="lazy" decoding="async" :src="photo.image" alt="" />
                    <span class="date">{{ formatPhotoDate(photo.Date) }}</span>
                </div>
            </div>
            <div class="buttons-container">
                <button
                    @click="
                        validateCheater();
                        goBackFunction();
                    "
                    class="confirm-btn"
                >
                    Confirm cheating attempt
                </button>
                <button
                    @click="
                        deleteCheater();
                        goBackFunction();
                    "
                    class="cancel-btn"
                >
                    Cancel cheating attempt
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponentBlue from "../components/LoaderComponentBlue";

import axios from "axios";

export default {
    name: "CheatingCard",
    components: { LoaderComponentBlue },
    data() {
        return {
            candidate: {},
            captured_photos: [],
            project_id: "",
            isLoading: true,
        };
    },

    methods: {
        formatPhotoDate(dateString) {
            const date = new Date(dateString);
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();

            const formattedTime = `${hours % 12 || 12}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
            const period = hours < 12 ? "AM" : "PM";

            return `${formattedTime} ${period}`;
        },
        deleteCheater() {
            axios.delete(`https://server.go-platform.com/anticheat/delete-cheater/${this.$route.params.id}`, { withCredentials: true }).catch((error) => {
                console.log(error);
            });
        },
        getCapturedPhotos() {
            axios
                .get(`https://server.go-platform.com/anticheat/photos/${this.$route.params.id}`, { withCredentials: true })
                .then((response) => {
                    this.captured_photos = response.data;
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        validateCheater() {
            axios
                .put(`https://localhos:8080/anticheat/changeToCheater/${this.$route.params.id}`, { withCredentials: true })
                .then(() => {
                    console.log("Changed to cheater succefully");
                })
                .then(() => this.deletePhotos())
                .catch((error) => {
                    console.log("fail change to cheater -------", error);
                    // this.isLoading = false;
                });
        },
        deletePhotos() {
            axios
                .delete(`https://server.go-platform.com/anticheat/delete-photos/${this.$route.params.id}`, { withCredentials: true })
                .then(() => {
                    console.log("deleted photos succefully");
                })

                .catch((error) => {
                    console.log("fail deleting photos", error);
                    // this.isLoading = false;
                });
        },
        getCheaterId() {
            axios
                .get(`https://server.go-platform.com/anticheat/cheater/${this.$route.params.id}`, { withCredentials: true })
                .then((response) => {
                    this.candidate = response.data;
                })
                .then((response) => {
                    this.project_id = response.data.project_id;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        goBackFunction() {
            this.$router.go(-1);
        },
    },
    mounted() {
        this.getCheaterId();
        this.getCapturedPhotos();
    },
};
</script>
<style scoped>
.cheating-container {
    height: 100%;
    width: 100%;
    display: flex;
    padding: 0;
    margin: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.left-side img {
    width: 200px;
    height: 250px;
}

.separator {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 90%;
    margin-top: 4rem;
}

h3 {
    color: #646464;
}

.line {
    width: 40%;
    height: 2px;
    background: #d9d9d9;
}

.cheating-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 800px;
    padding: 2rem 1rem;
    width: 100%;
}

.identity-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    background: #fff;
    border-radius: 20px;
    padding: 1rem;

    box-shadow:
        20px 20px 60px #bebebe,
        -20px -20px 60px #ffffff;
}

.identity-container {
    display: flex;
    align-items: center;
    gap: 2rem;

    width: 100%;
}

h1 {
    margin-bottom: 1rem;
    font-size: 18px;
    font-weight: 600;
}

.right-side {
    display: flex;
    flex-direction: column;
    align-items: start;
    height: 100%;
    justify-content: space-evenly;
}

.captured-photos {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    border-bottom: 2px solid #d9d9d9;
    margin-bottom: 2rem;
}

.captured-photos > div {
    width: 25%;
    /* Four columns, each taking 25% of the width */
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.captured-photos img {
    width: 100%;
    height: 170px;
}

.back-container {
    width: 100%;
    display: flex;
    color: #2196f3;
    margin-top: 2rem;
}

button {
    width: 25%;
    height: 60px;
    padding: 0.5rem;
    border-radius: 10px;
    border: none;
    background: #2196f3;
    font-weight: 500;
    color: #fff;
    box-shadow:
        0 2px 4px rgba(0, 0, 0, 0.1),
        0 4px 8px rgba(0, 0, 0, 0.1);

    &:hover {
        opacity: 0.85;
    }
}

.confirm-btn {
    background: #53b9ab !important;
}

.cancel-btn {
    background-color: red;
}

.buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 90%;
    padding-bottom: 2rem;
}

.back-container span:hover {
    text-decoration: underline;
}

.loading {
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
